import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"
import LocalizedLink from "./localizedLink"

function Footer({ posts, hidePostsMobile }) {
  posts = posts?.edges.length === 0 ? null : posts
  const { locale } = React.useContext(LocaleContext)
  const data = useStaticQuery(
    graphql`
      query {
        allPrismicHomepage {
          edges {
            node {
              id
              data {
                contact_description {
                  text
                  html
                }
                contact_button {
                  text
                  html
                }
                youen_title {
                  html
                  text
                }
                youen_subtitle {
                  html
                  text
                }
              }
              uid
              lang
            }
          }
        }
        allPrismicGlobal {
          edges {
            node {
              lang
              data {
                read_blogpost
              }
            }
          }
        }
      }
    `
  )
  const globals = data.allPrismicGlobal.edges.filter(
    global => global.node.lang === locale
  )
  return data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(footer => {
      return (
        <section key={"1"} className="gradient py-5 mt-28 ">
          {posts?.edges.map(post => {
            return (
              <article
                key={post.node.uid}
                className={`bg-white m-10 p-5 first:-mt-20 rounded-2xl shadow max-w-md md:mx-auto ${
                  hidePostsMobile ? "md:hidden" : ""
                }`}
              >
                <h5 className="text-xl text-center font-sans font-bold  text-comet">
                  {post.node.data.title.text}
                </h5>
                <LocalizedLink
                  className="block text-center font-sans font-bold uppercase bg-polar text-danube mt-5 w-full text-sm p-2 rounded-lg transition-colors hover:bg-danube hover:text-white "
                  to={post.node.uid}
                  sub={"blog"}
                >
                  {globals[0].node.data.read_blogpost}
                </LocalizedLink>
              </article>
            )
          })}
          <article
            className={`bg-white m-10 p-5 rounded-2xl shadow max-w-md md:mx-auto  ${
              hidePostsMobile ? "md:-mt-20" : ""
            }`}
          >
            <h5 className="text-xl text-center font-sans font-bold  text-comet">
              {footer.node.data.contact_description.text}
            </h5>
            <a
              className="block text-center font-sans font-bold uppercase bg-linen text-oldRose mt-5 w-full text-sm p-2 rounded-lg transition-colors hover:bg-oldRose hover:text-white "
              href="mailto:josselin.caer@gmail.com?subject=Contact%20From%20gatsby.josselin.pro"
            >
              {footer.node.data.contact_button.text}
            </a>
          </article>
          <div
            className="text-center font-sans font-bold text-gray-300 text-sm px-16 pb-10 "
            dangerouslySetInnerHTML={{
              __html: footer.node.data.youen_subtitle.html,
            }}
          />
        </section>
      )
    })
}

export default Footer
